html, body {
	height: 100%;
	box-sizing: border-box;
	color: #303030;
}

#container {
	display: flex;
	height: calc(100vh - 75px);
}

#wrapper {
	display: flex;
	flex-direction: column;
}

#fade {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: white;
	z-index: 100;
	opacity: 0;
	transition: opacity 1s; 
}

.inTransition {
	opacity: 1 !important;
}

#container > div {
	box-sizing: border-box;
}

#header {
	box-sizing: border-box;
	padding: 15px;
	display: flex;
	height: 75px;
	overflow: hidden;
	border-bottom: 1px solid lightgrey;
}

#header input, #header select {
	padding: 6px;
	border: 1px solid lightgrey;
	color: #737373;
	font-size: 14px;
	margin-top: 9px;
}

.ont {
	height: 33px;
	margin-top: 9px;
	background-color: #3b3b3b;
	color: white;
	font-weight: bolder;
	font-size: 16px;
	border-radius: 15px 0 0 15px;
	line-height: 33px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 10px;
}

select {
	border: 1px solid lightgrey;
	color: #737373;
	font-size: 14px;
}

#header input {
	padding: 7px;
	padding-left: 12px;
}

td {
	padding: 5px;
}

tr:nth-child(odd) {
	background-color: #fafcff;
}

.badge {
	background-color: #3b3b3b;
	padding: 8px;
	border-radius: 20px;
	font-size: 13px;
	color: #ffffff;
	font-weight: bolder;
}

.blue {
	background-color: #003563;
}

#header svg {
	margin-top: -10px;
	height: 65px;
	width: auto;
	margin-left: -5px;
}


/*
ul.c a:link, ul.c a:visited, ul.c a:hover, ul.c a:active {
	color: #001b42;
}

ul.d a:link, ul.d a:visited, ul.d a:hover, ul.d a:active {
	color: #ffa600;
}

ul.o a:link, ul.o a:visited, ul.o a:hover, ul.o a:active {
	color: #007304;
}

ul.c li::before {
  color: #003563 !important;
}

ul.d li::before {
  color: #ffca38 !important;
}

ul.o li::before {
  color: #00b017 !important;
}*/

.yellow {
	background-color: #eda807;
}

.green {
	background-color: #00b017;
}

table {
	font-size: 14px;
}

#version {
	margin-top: 12px;
	margin-left: 10px;
}

#overlay {
	position: fixed;
	width: 100%;
	height: 0;
	top: 75px;
	left: 0;
	background-color: black;
	opacity: 0;
	transition: opacity 0.15s ease-in-out;
	z-index: 1000;
}

.showOverlay {
	height: 100% !important;
	opacity: 0.6 !important;
	transition: opacity 0.75s ease-in-out !important;
}

#version {

}

#display {

}

ul {
	list-style: none;
}

ul li {
	padding: 2px;
}

#container ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: black; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

#left {
	display: flex;
	width: 50%;
}

#right {
	display: flex;
	width: 50%;
	justify-content: flex-end;
}

#results {
	position: absolute;
	left: 272px;
	background-color: white;
	min-width: 200px;
	max-height: 75vh;
	overflow: auto;
	box-sizing: border-box;
	z-index: 2000;
}

#results ul {
	list-style: none;
	margin: 0;
	padding: 0;
	border: 1px solid lightgrey;
}

#results ul li {
	font-weight: bolder;
	padding: 0;
	margin: 0;
}

#results ul li:before {
	content: "";
}

#results ul li:hover {
	background-color: #ebebeb;
}

#results ul li a {
	display:block;
	padding: 12px;
}

#results ul:empty {
	display: none;
}

#navigation {
	box-sizing: border-box;
	flex: 1;
	overflow: auto;
	height: 100%;
	width: 400px;
	flex: 2;
}

#navigation ul {
	margin: 0;
	padding-left: 25px;
}

#selectors {
	padding: 10px;
	display: flex;
	flex-direction: row;
}

#selectors > div {
	margin-right: 10px;
}

#content {
	padding: 25px;
	overflow: auto;
	height: 100%;
	flex-direction: column;
	flex: 3;
}

.hide {
	display: none;
}

#Visualization {
	position: relative;
	border: 1px solid lightgrey;
	background-color: #fff;
	background-image: radial-gradient(circle, #ebebeb 20%, transparent 10%), radial-gradient(circle, #ebebeb 20%, transparent 10%);
	background-size: 10px 10px;
	background-position: 0 0, 0 0;
}

#Controls {
	margin: 15px;
	border-radius: 5px;
	border: 1px solid lightgrey;
	background-color: white;
	padding: 15px;
	position: absolute;
	z-index: 105;
	right: 0;
}

#navWrap {
	display: flex;
	flex-flow: column wrap;
	/*background-color: #ebebeb;*/
}

 /* unvisited link */
a:link {
  color: #303030;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #303030;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: #303030;
  text-decoration: none;
}

/* selected link */
a:active {
  color: #303030;
  text-decoration: none;
} 